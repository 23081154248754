import useSWR from "swr";
import { VariantPriceInput, useProduct } from "@chordcommerce/react-autonomy";
import { stegaClean } from "@sanity/client/stega";

interface ReturnUseVariantPrice {
  discountPrice?: number;
}

export default function useVariantPrice(
  input: VariantPriceInput,
): ReturnUseVariantPrice {
  const { getPrice } = useProduct();
  const { data } = useSWR([input, "get-price"], (input) => {
    // remove sanity client stega encoding
    const variantId = stegaClean(input);
    return getPrice(variantId);
  });
  const { discountPrice, discount } = data || {};

  const isDiscountPrice =
    (discountPrice && discount && discount < 0) || undefined;

  return {
    discountPrice: isDiscountPrice && Number((discountPrice * 100).toFixed()),
  };
}
