import {
  LoginFormCopy,
  SlideOverDialog,
} from "@bluebottlecoffee/design-system/components";
import { useAuth } from "@chordcommerce/react-autonomy";
import { FunctionComponent } from "react";
import { AccountMenuCopy } from "../lib/account-menu";
import { Login } from "./Login";

export interface DialogData {
  copy: LoginFormCopy;
  accountMenuCopy: AccountMenuCopy;
  region: string;
  lang: string;
}

export const LoginDialog: FunctionComponent<DialogData> = ({
  copy,
  accountMenuCopy,
  ...defaultProps
}) => {
  const { isLoggedIn } = useAuth();

  const dialogTitle: string = isLoggedIn ? accountMenuCopy.account : copy.title;

  return (
    <SlideOverDialog
      dismissText={copy.dismissText}
      title={dialogTitle}
      contentType="login"
    >
      <Login copy={copy} accountMenuCopy={accountMenuCopy} {...defaultProps} />
    </SlideOverDialog>
  );
};
