import { AccountMenuLinkSection } from "@bluebottlecoffee/design-system/components";
import { accountPage } from "./link-builders";

export type AccountMenuCopy = {
  account: string;
  addresses: string;
  credits: string;
  currentSubscription: string;
  logOut: string;
  orderHistory: string;
  personalInformation: string;
};

export const accountSlugs = {
  personalInfo: "personal-information",
  orderHistory: "order-history",
  credits: "credits",
  addresses: "addresses",
  currentSubscriptions: "current-subscriptions",
};

export const accountMenuLinks = (
  copy: AccountMenuCopy,
  region: string,
  lang: string,
) => [
  {
    text: copy.personalInformation,
    url: accountPage({ region, lang, slug: accountSlugs.personalInfo }),
    active: false,
  },
  {
    text: copy.currentSubscription,
    url: accountPage({
      region,
      lang,
      slug: accountSlugs.currentSubscriptions,
    }),
    active: false,
  },
  {
    text: copy.orderHistory,
    url: accountPage({ region, lang, slug: accountSlugs.orderHistory }),
    active: false,
  },
  {
    text: copy.credits,
    url: accountPage({ region, lang, slug: accountSlugs.credits }),
    active: false,
  },
  {
    text: copy.addresses,
    url: accountPage({ region, lang, slug: accountSlugs.addresses }),
    active: false,
  },
];

export default (
  region: string,
  lang: string,
  copy: AccountMenuCopy,
  logoutCallback: () => Promise<void>,
): AccountMenuLinkSection[] => [
  {
    label: copy.account,
    links: accountMenuLinks(copy, region, lang),
  },
  {
    label: copy.logOut,
    links: [],
    onClick: logoutCallback,
  },
];
