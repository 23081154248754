import { toHTML } from "@portabletext/to-html";
import DineroFactory from "dinero.js";
import {
  CartLineItemsProps,
  CartProps,
  FreeShippingBarProps,
  OrderSummaryProps,
} from "@bluebottlecoffee/design-system/components";
import { ProductRecsProps } from "@bluebottlecoffee/design-system/components/ProductRecs/ProductRecs";
import { Money } from "@bluebottlecoffee/design-system/components/lib/types";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/components/GiftCard/GiftCardForm";
import {
  GiftMessageFormProps,
  LineItemsProps,
  PromoFormProps,
} from "@bluebottlecoffee/design-system/components/OrderSummary/components";
import { useRouter } from "next/router";
import {
  ModifyCart,
  OrderBig,
  PrepareCheckout,
} from "@chordcommerce/react-autonomy";
import { loadStripe } from "@stripe/stripe-js";
import { Dispatch, SetStateAction } from "react";
import useVariantPrice from "../chord/hooks/components/use-variant-price";
import { parseCookie } from "../utils/parse-cookie";
import { productPage } from "../link-builders";
import {
  Cart as CartSchema,
  FreeShippingBar as FreeShippingBarSchema,
  GiftMessageCopy as GiftMessageCopySchema,
  LineItems as LineItemsSchema,
  OrderSummary as OrderSummarySchema,
  PromoCopy as PromoCopySchema,
} from "../sanity-schema";
import { toLinkProps } from "./link";
import { DereferencedSubALCAddOnSchema } from "./sub-alc-add-on";
import { Dialect } from "../utils/locale";
import { isFeatureEnabled } from "../utils/is-feature-enabled";
import { getIsClientSide } from "../utils";

export function toFreeShippingBarProps(
  data: FreeShippingBarSchema,
  lang: string,
): Omit<FreeShippingBarProps, "estimatedTotal"> {
  return {
    aboveThresholdText: data.aboveThresholdText[lang],
    belowThresholdText: data.belowThresholdText[lang],
    freeShipThreshold: data.freeShipThreshold as Money,
  };
}

export function toLineItemsProps(
  data: LineItemsSchema,
  lang: string,
): Omit<LineItemsProps, "grossSubtotal" | "currency"> {
  return {
    creditLabel: data.creditLabel[lang],
    discountLabel: data.discountLabel[lang],
    estimatedTotalLabel: data.estimatedTotalLabel[lang],
    free: data.free[lang],
    freeShippingText: data.freeShippingText[lang],
    grossSubtotalLabel: data.grossSubtotalLabel[lang],
    item: data.item[lang],
    shippingLabel: data.shippingLabel[lang],
    taxesLabel: data.taxesLabel[lang],
    tbd: data.tbd[lang],
  };
}

export function toGiftMessageFormProps(
  data: GiftMessageCopySchema,
  lang: string,
): Omit<GiftMessageFormProps, "addGiftMessage" | "removeGiftMessage"> {
  return {
    copy: {
      add: data.add[lang],
      cancel: data.cancel[lang],
      to: data.to[lang],
      title: data.title[lang],
      from: data.from[lang],
      message: data.message[lang],
      saveMessageButton: data.saveMessageButton[lang],
      removeMessageButton: data.removeMessageButton[lang],
      fromValidationMessage: data.fromValidationMessage[lang],
      toValidationMessage: data.toValidationMessage[lang],
      giftNoteValidationMessage: data.giftNoteValidationMessage[lang],
    },
    removeGiftMessageAlert: {
      remove: data.removeGiftMessageAlert.remove[lang],
      verify: data.removeGiftMessageAlert.verify[lang],
      yes: data.removeGiftMessageAlert.yes[lang],
      no: data.removeGiftMessageAlert.no[lang],
    },
    giftMessageCartCopy: {
      from: data.from[lang],
      to: data.to[lang],
      message: data.message[lang],
    },
  };
}

export function toPromoFormCopyProps(
  data: PromoCopySchema,
  lang: string,
): Pick<PromoFormProps, "promoCopy"> {
  return {
    promoCopy: {
      add: data.add[lang],
      apply: data.apply[lang],
      placeholder: data.placeholder[lang],
    },
  };
}
export function toRemovePromoAlertProps(
  data: PromoCopySchema,
  lang: string,
): Pick<PromoFormProps, "removePromoAlert"> {
  return {
    removePromoAlert: {
      remove: data.removePromoAlert.remove[lang],
      verify: data.removePromoAlert.verify[lang],
      yes: data.removePromoAlert.yes[lang],
      no: data.removePromoAlert.no[lang],
    },
  };
}
export function toPromoFormProps(
  data: PromoCopySchema,
  lang: string,
): Omit<PromoFormProps, "removePromoCode" | "addPromoCode"> {
  return {
    ...toPromoFormCopyProps(data, lang),
    ...toRemovePromoAlertProps(data, lang),
  };
}

type OrderSummarySchemaProps = Omit<
  OrderSummaryProps,
  | "lineItems"
  | "promoForm"
  | "ctaClick"
  | "subsPresent"
  | "giftMessageForm"
  | "cartLineItems"
> & {
  giftMessageForm: Omit<
    GiftMessageFormProps,
    "addGiftMessage" | "removeGiftMessage"
  >;
  lineItems: Omit<LineItemsProps, "grossSubtotal" | "currency">;
  promoForm: Omit<PromoFormProps, "removePromoCode" | "addPromoCode">;
};
export function toOrderSummaryProps(
  data: OrderSummarySchema,
  lang: string,
): OrderSummarySchemaProps {
  return {
    lineItems: toLineItemsProps(data.lineItems, lang),
    promoForm: toPromoFormProps(data.promoCopy, lang),
    giftMessageForm: toGiftMessageFormProps(data.giftMessageCopy, lang),
    subsAgreement: {
      text: data.subsAgreement.subsAgreement[lang],
      title: data.subsAgreement.title[lang],
      onChange: () => {},
    },
    cta: {
      text: data.cta[lang],
    },
    heading: data.heading[lang],
  };
}

interface ContinueToCheckout {
  modifyCart: (options: ModifyCart) => Promise<OrderBig>;
  region: Dialect["region"];
  lang: Dialect["lang"];
  // remove after checkout is fully migrated to embedded checkout
  onError?: Dispatch<SetStateAction<string>> | undefined;
  prepareCheckout: (options?: PrepareCheckout) => Promise<OrderBig>;
}

export const continueToCheckout = ({
  modifyCart,
  region,
  lang,
  // remove after checkout is fully migrated to embedded checkout
  onError,
  prepareCheckout,
}: ContinueToCheckout) => {
  const router = useRouter();
  /**
   * Add Iterable campaign and template ID to Order metadata if values
   * are stored in cookies and castable to number
   *
   * IDs previously set on the Order metadata are removed if the relevant
   * cookies either a) have expired since the last time the method was
   * invoked, or b) no longer evaluate to a number.
   *
   * @returns { Promise<void> }
   */
  async function addOrRemoveCampaignMetadata(): Promise<void> {
    const {
      iterableEmailCampaignIdRevenueAttributable,
      iterableEmailTemplateIdRevenueAttributable,
    } = parseCookie();

    try {
      // A metadata key is removed by setting its value to an empty string:
      // https://chord.stoplight.io/docs/chord-oms/e8bbbcd029277-update-order
      await modifyCart({
        attributes: {
          metadata: {
            campaignId: +iterableEmailCampaignIdRevenueAttributable
              ? +iterableEmailCampaignIdRevenueAttributable
              : "",
            templateId: +iterableEmailTemplateIdRevenueAttributable
              ? +iterableEmailTemplateIdRevenueAttributable
              : "",
          },
        },
      });
    } catch (error) {
      console.error(
        "Failed to modify Iterable campaign metadata on Order:",
        error,
      );
    }
  }

  return async () => {
    await addOrRemoveCampaignMetadata();
    if (isFeatureEnabled(process.env.NEXT_PUBLIC_ENABLE_EMBEDDED_CHECKOUT)) {
      router.push(`/${region}/${lang}/checkout`);
    } else {
      await prepareCheckout()
        .then(async (checkoutCart) => {
          // @ts-ignore
          if (checkoutCart.checkoutUrl && getIsClientSide()) {
            // @ts-ignore
            window.location = checkoutCart.checkoutUrl;
          } else {
            const stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK);
            stripe.redirectToCheckout({
              sessionId: checkoutCart.checkoutSessionId,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          onError?.(err.errors ? err.errors[0] : err.message);
        });
    }
  };
};

export function toCartLineItemsProps(
  data: CartSchema,
  lang: string,
  giftCardFormCopy: GiftCardFormCopy,
  subscriptionAddOnCopy?: DereferencedSubALCAddOnSchema,
): Omit<
  CartLineItemsProps,
  | "cartLineItems"
  | "removeFromCart"
  | "modifyQuantity"
  | "modifyGiftCards"
  | "emailIsValid"
  | "modifyCart"
  | "useVariantPrice"
  | "useVariantAvailability"
> {
  return {
    copy: {
      addOnsDescription: toHTML(
        subscriptionAddOnCopy?.selectedProductsDescription?.[lang],
      ),
      addOnsHeading: subscriptionAddOnCopy?.selectedProductsHeader?.[lang],
      cartHeading: data.heading[lang],
      discountPriceLabel: data.discountPriceLabel[lang],
      removeItemButtonLabel: data.removeItemButtonLabel[lang],
      soldOutlabel: data.orderSummary.lineItems.soldOutLabel[lang],
      subscriptionBenefitsCopy: toHTML(data.subscriptionBenefitsCopy[lang]),
      subscribeOptions: data.subscribeOptions[lang],
      subscriptionPillLabel: data.subscriptionPillLabel[lang],
      toggleSubscriptionLabel: data.toggleSubscriptionLabel[lang],
      updatingYourAddOnsLabel:
        subscriptionAddOnCopy?.updatingYourAddOnsLabel?.[lang],
      updatingYourCartLabel: data.updatingYourCartLabel[lang],
      yourItems: data.yourItems[lang],
    },
    giftCardFormCopy,
  };
}

export function toProductRecsProps(
  data,
  region: string,
  lang: string,
  currency: DineroFactory.Currency,
): ProductRecsProps["productRecs"] {
  return data.map((rec) => ({
    useVariantPrice,
    description: rec.description[lang],
    images: rec.images.map((image) => ({
      desktop: {
        altText: image.desktop.altText[lang],
        src:
          `/${image.desktop?.source.public_id}.${image.desktop?.source.format}` ||
          "/v1612883439/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png",
      },
      mobile: {
        altText: image.mobile?.altText[lang],
        src:
          `/${image.mobile?.source.public_id}.${image.mobile?.source.format}` ||
          `/${image.desktop?.source.public_id}.${image.desktop?.source.format}` ||
          "/v1612883439/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png",
      },
    })),
    name: rec.name[lang],
    slug: productPage({
      slug: rec.slug.current,
      ...{ region, lang },
    }),
    subscription: {
      // quick fix
      intervals: [
        {
          unit: "week",
          length: 2,
        },
      ],
    },
    // subscription: rec.subscription, // This is coming back from Algolia as a reference. We would need to update it there and select the default interval, but instead hard code it above..
    subscriptionOptions: rec.subscriptionOptions?.map((option) => ({
      item: option.item,
      quantityLabel: option.quantityLabel,
    })),
    subscriptionType: rec.subscriptionType,
    variants: rec.variants.map((variant) => ({
      id: parseInt(variant._id.replace("variant_", ""), 10),
      cartLimit: variant.cartLimit,
      images:
        variant.images?.map((image) => ({
          desktop: {
            altText: image.desktop.altText[lang],
            src:
              `/${image.desktop?.source.public_id}.${image.desktop?.source.format}` ||
              "/v1612883439/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png",
          },
          mobile: {
            altText: image.mobile?.altText[lang],
            src:
              `/${image.mobile?.source.public_id}.${image.mobile?.source.format}` ||
              `/${image.desktop?.source.public_id}.${image.desktop?.source.format}` ||
              "/v1612883439/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png",
          },
        })) ?? [],
      initialInventoryCount: variant.initialInventoryCount,
      price: {
        amount: variant.price,
        currency: currency ?? "USD", // TODO: determine a smarter default
      },
      optionValues: variant.optionValues.map((value) => ({
        presentation: value.presentation,
      })),
      regularPrice: {
        amount: variant.regularPrice,
        currency: currency ?? "USD", // TODO: determine a smarter default
      },
      sku: variant.sku,
    })),
  }));
}

export function toProductRecsCopyProps(
  data: CartSchema,
  lang: string,
  conversionHeading?: string,
): Pick<ProductRecsProps, "productRecsCopy"> {
  return {
    productRecsCopy: {
      desktop: {
        atcLoadingText: data.productRecsCopy.desktop.atcLoadingText[lang],
        buttonLabel: data.productRecsCopy.desktop.buttonLabel[lang],
        heading:
          conversionHeading || data.productRecsCopy.desktop.heading[lang],
      },
      mobile: {
        atcLoadingText: data.productRecsCopy.mobile.atcLoadingText[lang],
        buttonLabel: data.productRecsCopy.mobile.buttonLabel[lang],
        heading: conversionHeading || data.productRecsCopy.mobile.heading[lang],
      },
      emptyCart: {
        atcLoadingText: data.productRecsCopy.emptyCart.atcLoadingText[lang],
        buttonLabel: data.productRecsCopy.emptyCart.buttonLabel[lang],
        heading: data.productRecsCopy.emptyCart.heading[lang],
      },
    },
  };
}

export type CartSchemaProps = Omit<
  CartProps,
  | "freeShippingBarProps"
  | "orderSummaryProps"
  | "cartLineItemsProps"
  | "productRecsProps"
> & {
  freeShippingBarProps: Omit<FreeShippingBarProps, "estimatedTotal">;
  orderSummaryProps: OrderSummarySchemaProps;
  cartLineItemsProps: Omit<
    CartLineItemsProps,
    | "cartLineItems"
    | "removeFromCart"
    | "modifyQuantity"
    | "modifyGiftCards"
    | "emailIsValid"
    | "modifyCart"
    | "useVariantPrice"
    | "useVariantAvailability"
  >;
  productRecsProps: Omit<
    ProductRecsProps,
    "addToCartClick" | "emptyCart" | "productRecs" | "productRecShopCards"
  >;
};
export function toCartProps(
  data: CartSchema,
  giftCardFormCopy: GiftCardFormCopy,
  region: string,
  lang: string,
): CartSchemaProps {
  return {
    emptyCartCopy: data.emptyCartCopy[lang],
    cartLineItemsProps: toCartLineItemsProps(data, lang, giftCardFormCopy),
    freeShippingBarProps: toFreeShippingBarProps(data.freeShippingBar, lang),
    orderSummaryProps: toOrderSummaryProps(data.orderSummary, lang),
    productRecsProps: toProductRecsCopyProps(data, lang),
    returnToShopLink: toLinkProps(data.returnToShopLink, region, lang),
  };
}
