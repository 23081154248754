import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Icon,
  ShopCardProps,
  SlideOverDialog,
  useScreenSizeContext,
  useSlideOverDialogContext,
} from "@bluebottlecoffee/design-system/components";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import {
  Configure,
  InstantSearch,
  SearchBox,
  useHits,
  useSearchBox,
} from "react-instantsearch";
import stripHtml from "striptags";
import { ShippingCopy } from "../lib/sanity-schema";
import { publicSearchClient } from "../lib/algolia/clients";
import { SearchResults } from "../lib/algolia/instant-search/components/search-results";
import { localizedProductsIndexSearchableAttributes } from "../lib/algolia/indices";
import { ConversionCopy } from "../pages/[region]/[lang]/product/[slug]";

export type SearchDialogCopy = {
  productSearchCopy: {
    inputPlaceholder: string;
    searchResultsCopy: string;
    searchResultsCopyDefault: string;
    searchResultsNotFoundCopy: string;
    searchResultsNotFoundSuggestionCopy: string;
    closeButton: string;
  };
  shopcardCopy: {
    shippingCopy: ShippingCopy;
    conversionCopy: ConversionCopy;
  };
};

type SearchDialogProps = SearchDialogCopy & {
  lang: string;
  region: string;
  noResultsShopCards: ShopCardProps[];
};

const searchIndexName = `${process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET}-products`;

export const SearchDialogContent: FunctionComponent<SearchDialogProps> = ({
  lang,
  region,
  productSearchCopy,
  shopcardCopy,
  noResultsShopCards,
}) => {
  const {
    results: { query },
  } = useHits();
  const { refine } = useSearchBox();
  const { activeContentType } = useSlideOverDialogContext();
  const { asPath } = useRouter();

  useEffect(() => {
    if (activeContentType !== "search") return;

    const getSearchDialogText = Cookies.get("searchDialog");
    const backToSearchLogic = asPath.includes("search=true");

    if (getSearchDialogText && backToSearchLogic) {
      refine(getSearchDialogText);
    }
  }, [activeContentType]);

  return (
    <>
      <SearchResults
        lang={lang}
        region={region}
        productSearchCopy={productSearchCopy}
        shopcardCopy={shopcardCopy}
        noResultsShopCards={noResultsShopCards}
        searchIndexName={searchIndexName}
      />
      <Configure
        // @ts-ignore
        clickAnalytics
        hitsPerPage={query ? 6 : 30} // TODO: determine hit limit? pagination?
        distinct
        filters="forSale:true"
        restrictSearchableAttributes={localizedProductsIndexSearchableAttributes(
          lang,
        )}
      />
    </>
  );
};

export const SearchDialog: FunctionComponent<SearchDialogProps> = ({
  productSearchCopy,
  ...props
}) => {
  const searchIconBuilder = useCallback(
    () => <Icon hoverOpacity={false} name="search" />,
    [],
  );

  const [renderSearchBox, setRenderSearchBox] = useState(false);
  const { isMobile } = useScreenSizeContext();
  const { activeContentType, setActiveContentType } =
    useSlideOverDialogContext();

  useEffect(() => {
    if (activeContentType === "search") {
      // SlideOverDialog makes the dismissButton the focus; with this timeout, we force the SearchBox to be the focus.
      // https://github.com/bluebottlecoffee/design-system/blob/main/components/SlideOverDialog/SlideOverDialog.tsx#L137
      setTimeout(() => {
        setRenderSearchBox(true);
      }, 50);
    }

    return () => {
      setRenderSearchBox(false);
    };
  }, [activeContentType]);

  return (
    <InstantSearch
      indexName={searchIndexName}
      searchClient={publicSearchClient}
      insights
    >
      <SlideOverDialog
        contentType="search"
        dialogBackgroundColor="white"
        dismissText="close"
        className="search-dialog-slideOverDialog"
        title="Search Dialog"
        isFullWidth
        showDismissButton={false}
      >
        <div className="search-dialog-header">
          {!isMobile() && <Icon name="blueBottleLogo" ariaHidden />}

          {renderSearchBox && (
            <SearchBox
              classNames={{
                root: "search-dialog-input-container-test search-dialog-input-container",
                input: "search-dialog-input",
                submit: "search-dialog-input-submit",
              }}
              placeholder={productSearchCopy.inputPlaceholder}
              loadingIconComponent={() => null} // Algolia default renders this and it's weird
              resetIconComponent={() => null} // Algolia default renders an extra?!
              submitIconComponent={searchIconBuilder}
              queryHook={(query, search) => {
                search(stripHtml(query));
                Cookies.set("searchDialog", query);
              }}
              autoFocus
            />
          )}

          <button
            type="button"
            aria-label={productSearchCopy.closeButton}
            onClick={() => setActiveContentType(null)}
          >
            <Icon ariaHidden name="x" />
          </button>
        </div>
        <SearchDialogContent productSearchCopy={productSearchCopy} {...props} />
      </SlideOverDialog>
    </InstantSearch>
  );
};
